import React, {  forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Image from 'next/image'
import { addDays } from 'date-fns'

export default function Datepicker({ labelText, onChange, selected, errorText }) {
  const CustomPicker = forwardRef(({ value, onClick }, ref) => (
    <button
      style={{
        boxShadow: 'none',
        border: errorText ? '1px solid rgb(255 0 0)' : '1px solid #C9C9C9',
        '&:hover': {
          border: '1px solid #C9C9C9'
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: ' 0.75rem 0.5rem',
        borderRadius: 8,
        width: '100%',
        backgroundColor: '#fafafa',
        color: '#c9c9c9',
        fontSize: '0.8rem'
      }}
      className='h-10 md:h-12'
      onClick={onClick}
      ref={ref}
    >
      {value}
      <Image
        loading='lazy'
        src='/images/calender.svg'
        alt='calender'
        height={15}
        width={15}
      />
    </button>
  ))

  return (
    <section className='w-full'>
      <label
        htmlFor={labelText}
        className='text-lg text-priColor mb-2 font-regular font-bold leading-relaxed inline-block'
      >
        {labelText}
      </label>
      <DatePicker
        name={labelText}
        placeholderText='DD/MM/YYYY'
        dateFormat='dd/MM/yyyy'
        minDate={new Date()}
        maxDate={addDays(new Date(), 5)}
        selected={selected || new Date()}
        onChange={onChange}
        customInput={<CustomPicker />}
      />
    </section>
  )
}
